import {CurrentUserResponseModel, CurrentUserSliceDataModel, CurrentUserSliceModel} from "./currentUserSliceModel";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import CurrentUserService from "../../services/currentUserService";

const currentUserAPI = new CurrentUserService();

const loadCurrentUserStorage = () => {
    try {
        const serializedState = localStorage.getItem("current_user");
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
}

const saveCurrentUserStorage = (state: CurrentUserSliceDataModel) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("current_user", serializedState);
    } catch (err) {
        console.error(err)
    }
}

export const resetCurrentUserStorage = () => {
    try {
        localStorage.removeItem("current_user");
        localStorage.removeItem("csrf_token");
    } catch (err) {
        console.error(err)
    }
}

export const getCurrentUser = createAsyncThunk(
    'currentUser/get',
    async (_, thunkAPI) => {
        try {
            const storage = loadCurrentUserStorage();
            if (storage) {
                return storage;
            }

            resetCurrentUserStorage();

            const response = await currentUserAPI.getCurrentUser();
            const currentUser = {} as CurrentUserSliceDataModel;

            if (response.data !== undefined && response.data.data !== undefined) {
                const data = response.data.data as CurrentUserResponseModel;
                currentUser.id = data.drupal_internal__uid;
                currentUser.name = data.display_name;
                currentUser.relation = data.active_relation?.meta?.drupal_internal__target_id ?? null,
                currentUser.relation_uuid = data.active_relation?.id ?? null;
                currentUser.data = data;
            }

            saveCurrentUserStorage(currentUser);

            return currentUser;
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    }
)

const persistedState = loadCurrentUserStorage();

const initialState = {
    loading: false,
    currentUser: persistedState
} as CurrentUserSliceModel;

const currentUserSlice = createSlice({
    name: "currentUser",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCurrentUser.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getCurrentUser.fulfilled, (state) => {
            state.currentUser = loadCurrentUserStorage();
            state.loading = false;
        })
        builder.addCase(getCurrentUser.rejected, (state) => {
            state.loading = false;
        })
    }
});

export default currentUserSlice.reducer;
