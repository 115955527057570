import {Form} from "react-bootstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";

export function HeaderSearch() {
    const { t } = useTranslation('common');
    const [searchText, setSearchText] = useState('');
    const navigation = useNavigate();
    const {lang} = useParams();

    const handleFormSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        navigation(`/${lang}/assortment?search=${searchText}`)
    };

    const handleInputChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSearchText(event.target.value);
    };

    return (
        <>
            <Form onSubmit={handleFormSubmit}>
                <Form.Group>
                    <Form.Control
                        className={"search"}
                        type="text"
                        placeholder={t('Search on productname or articlenumber') || ''}
                        value={searchText}
                        onChange={handleInputChange}
                    />
                </Form.Group>
            </Form>
        </>
    );
}
