import {CornyBakersEnv} from "../../../../shared/environment/features/CornyBakersEnvironment";
import axios, {RawAxiosRequestHeaders} from "axios";

interface CurrentUerRequestHeaders {
    'Content-Type': string,
    'Authorization'?: string,
}

export default class CurrentUserService {
    private readonly api_token: string|null;
    private readonly api_url: string;

    constructor() {
        this.api_token = null;
        this.api_url = CornyBakersEnv.API_BASE_URL;
    }

    init = () => {
        const headers: CurrentUerRequestHeaders = {
            'Content-Type': 'application/json'
        }

        if (this.api_token) {
            // TODO: headers.Authorization = `Bearer ${this.api_token}`;
        }

        axios.defaults.withCredentials = true;

        return axios.create({
            baseURL: this.api_url,
            headers: headers as RawAxiosRequestHeaders,
        });
    };

    getCurrentUser = () => {
        return this.init().get('/jsonapi/current-user?jsonapi_include=1&include=active_relation')
    }
}
