import {Badge, Card} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {AddToCart} from "../../../../cart/modules/pages/cart/addToCart";
import {ProductModel} from "../../../domain/models/productModel";
import {FavoriteBtn} from "../../../../productFavorites/modules/commons/favoriteBtn";
import React, {useState} from "react";
import default150 from "../../commons/images/product/default-150.png";
import {formatNum} from "../../../../general/features/util";

export const onProdImgError = (e: React.SyntheticEvent) => {
    (e.target as HTMLImageElement).src = default150;
}

export function ProductCard(props: { product: ProductModel }) {
    const {product} = props;
    const {lang} = useParams();

    const navigation = useNavigate();
    const thumbnail = product.images.length > 0 ? product.images[0].thumbnail : default150;
    const gotoProduct = (event: {preventDefault: () => void}) => {
        event.preventDefault();

        navigation(`/${lang}/assortment/${product.id}`)
    };

    const [selectedQuantity, setSelectedQuantity] = useState(1);

    const addQuantity = (quantity: number) => {
        setSelectedQuantity(quantity);
    };

    return (
        <Card className={'product-card'}>
            <a onClick={gotoProduct} href={`/${lang}/assortment/${product.id}`}>
                <Card.Img variant={'top'} src={thumbnail} onError={onProdImgError} draggable={false}/>
            </a>
            <Card.Body className={"d-flex flex-column justify-content-between"}>
                <a onClick={gotoProduct} href={`/${lang}/assortment/${product.id}`}>
                    <Card.Title>{product.name ?? ''}</Card.Title>
                    <Card.Text className={'article-number'}>
                        Art. nr.: {product.sku ?? ''}
                    </Card.Text>
                </a>
                <div>
                    <div className={'product-price-badge'}>
                        {(product?.scaled_prices?.length ?? 0) > 0 ? product.scaled_prices.map((item, key) =>
                            <Badge key={key} bg={'primary'} onClick={() => addQuantity(item.quantity > 0 ? item.quantity : 1)}>
                                {formatNum(`${item.price ?? ''}`)}
                                {item.quantity > 0 && <small>{item.quantity} st.</small>}
                            </Badge>
                        ) : (
                            <Badge bg={'primary'}>{product.price ?? ''}</Badge>
                        )}
                    </div>
                    <div className={'add-to-cart justify-content-between d-flex'}>
                        <div className={'d-flex flex-row flex-grow'}>
                            <div className={'d-flex'}>
                                <AddToCart variationId={product.variations.drupal_internal__variation_id}
                                           variant={'card'}
                                           quantity={selectedQuantity}
                                />
                            </div>
                        </div>
                        <div>
                            <FavoriteBtn size={'25px'} productId={product.id} variant={'card'}/>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}
