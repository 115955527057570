import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios, {AxiosError} from "axios";
import {CornyBakersEnv} from "../../../../../shared/environment/features/CornyBakersEnvironment";
import {Category, MenuSliceModel} from "./menuSliceModel";
import i18next from "i18next";

const API_BASE_URL = CornyBakersEnv.API_BASE_URL;

interface CategoryResponseItem {
    id: string;
    attributes: {
        name: string;
        weight: number;
        drupal_internal__tid: number;
    },
    relationships: {
        parent: {
            data: { id: string }[]
        }
    }
}

export const getCategories = createAsyncThunk(
    "menuSlice/getCategories",
    async (_, thunkAPI) => {
        const lang = i18next.language;
        const formatLang = lang === 'nl' ? '' : `/${lang}`;

        const url = `${API_BASE_URL}${formatLang}/jsonapi/index/products?filter[entity_type]=taxonomy_term`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const productsResponse = response.data;

            if (productsResponse.data.length === 0) {
                return thunkAPI.rejectWithValue('No products found');
            }

            const categories = response.data.data.map((item: CategoryResponseItem) => ({
                id: item.id,
                name: item.attributes.name,
                weight: item.attributes.weight,
                drupal_id: item.attributes.drupal_internal__tid,
                parent: (item.relationships.parent.data.length > 0 && item.relationships.parent.data[0].id !== 'virtual' ? item.relationships.parent.data[0].id : null),
            }));

            return (categories as Category[])
                .sort((itemA: Category, itemB: Category) => itemA.weight > itemB.weight ? 1 : -1);

        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return thunkAPI.rejectWithValue(error.message);
            }
            return thunkAPI.rejectWithValue('Something went wrong, please try again');
        }
    });

const initialState = {
    loading: false,
    loaded: false,
    categories: [] as Category[],
    mainCategories: [] as Category[],
} as MenuSliceModel;

const menuSlice = createSlice({
    name: "menuSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCategories.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCategories.rejected, (state) => {
                state.loading = false;
                state.loaded = true;
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.loaded = true;
                state.categories = action.payload as Category[];
                state.mainCategories = (action.payload as Category[])
                    .filter((item: Category) => (item?.parent ?? null) === null)
                    .sort((itemA: Category, itemB: Category) => itemA.weight > itemB.weight ? 1 : -1);
            })
    }
});

export default menuSlice.reducer;
